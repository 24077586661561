import React, { useEffect } from "react";
import Header from "../../Layout/Header/Header";
import Footer from "../../Layout/Footer/Footer";
import Countdown from "react-countdown";
import "../About/ABT-Convention/Abtconvention.css";
import { useDispatch, useSelector } from "react-redux";
import "./home.css";

import { Grid, Button } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { Link, useParams,useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import {
  fetchCmsPages3,
  fetchCmsPages,
  fetchCmsPages2,
  fetchSpeakerData,
} from "../../Redux/Slice";
// import Grid from '@mui/material/Grid';

import Slider from 'react-slick';
import { Box } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import $ from 'jquery';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import 'owl.carousel';

function Home() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const Record = useSelector((state) => state.cms.speaker_Record.data);

  const speaker_Record = Record?.filter((i)=>i.is_dignitaries)

  
  const location = useLocation();

  const leftSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const rightSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true, // For bottom-to-top movement
    arrows: false,
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);

    console.log("pathname",location.pathname + location.search)

  }, [location]);


  useEffect(() => {


    dispatch(fetchSpeakerData());
    if (slug) {
      console.log("Fetching CMS page with slug:", slug);
      dispatch(fetchCmsPages());
      dispatch(fetchSpeakerData());
      dispatch(fetchCmsPages2());
      dispatch(fetchCmsPages3(slug));
    }
  }, [dispatch, slug]);

 


    

  console.log("speaker_Record", speaker_Record);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Event has started!</span>;
    } else {
      return (
        <div className="countdown-wrapper ">
          <div className="time-box ">
            <span className="number">{days}</span> <br />
            <span className="label">Days</span>
          </div>
          <span className="midSpn">:</span>
          <div className="time-box2 ">
            <span className="number">0{hours}</span>
            <br />
            <span className="label">Hours</span>
          </div>

          <span className="midSpn">:</span>
          <div className="time-box3 ">
            <span className="number">{minutes}</span>
            <br />
            <span className="label">Minutes</span>
          </div>
          <span className="midSpn">:</span>
          <div className="time-box4 ">
            <span className="number">{seconds}</span>
            <br />
            <span className="label">Seconds</span>
          </div>
        </div>
      );
    }
  };

  const images = [
    "https://www.fhraiconvention.com/cdn/images/icons/icon-1.png",
    "https://www.fhraiconvention.com/cdn/images/icons/icon-1.png",
    "https://www.fhraiconvention.com/cdn/images/icons/icon-1.png"
];

const logo = "https://www.fhraiconvention.com/fhrai-logo.png";

  useEffect(() => {
    const tabButtons = document.querySelectorAll(".tab-btn");
    const tabsContent = document.querySelectorAll(".tab");

    tabButtons.forEach((button) => {
      button.addEventListener("click", () => {
        tabButtons.forEach((btn) => btn.classList.remove("active-btn"));
        tabsContent.forEach((tab) => tab.classList.remove("active-tab"));

        button.classList.add("active-btn");
        const target = document.querySelector(button.dataset.tab);
        target.classList.add("active-tab");
      });
    });
  }, []);
  return (
    <div className="page-wrapper">
      {/* <Header/> */}
      {/* <!-- Banner Conference Two --> */}
      <section
        className="banner-conference-two"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/6.jpg)",
        }}
      >
      
        <div className="icons parallax-scene-1">
    
          <div
            data-depth="0.20"
            className="icon-one parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-1.png)",
            }}
          ></div>
      
          <div
            data-depth="0.50"
            className="icon-two parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-2.png)",
            }}
          ></div>
          
          <div
            data-depth="0.10"
            className="icon-three parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-3.png)",
            }}
          ></div>
      
          <div
            data-depth="0.30"
            className="icon-four parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-4.png)",
            }}
          ></div>
   
          <div
            data-depth="0.10"
            className="icon-five parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-5.png)",
            }}
          ></div>
       
          <div
            data-depth="0.20"
            className="icon-six parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-6.png)",
            }}
          ></div>
      
          <div
            data-depth="0.10"
            className="icon-seven parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-7.png)",
            }}
          ></div>
         
          <div
            data-depth="0.20"
            className="icon-eight parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-1.png)",
            }}
          ></div>
         
          <div
            data-depth="0.50"
            className="icon-nine parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-8.png)",
            }}
          ></div>
         
          <div
            data-depth="0.10"
            className="icon-ten parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-3.png)",
            }}
          ></div>
         
          <div
            data-depth="0.30"
            className="icon-eleven parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-4.png)",
            }}
          ></div>
         
          <div
            data-depth="0.10"
            className="icon-twelve parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-5.png)",
            }}
          ></div>
         
          <div
            data-depth="0.20"
            className="icon-thirteen parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-6.png)",
            }}
          ></div>
       
          <div
            data-depth="0.10"
            className="icon-fourteen parallax-layer"
            style={{
              backgroundImage:
                "url(https://www.fhraiconvention.com/cdn/images/icons/icon-7.png)",
            }}
          ></div>
        </div>
        <div className="images-outer">
          <figure className="speaker-img">
            <img
              src="https://www.fhraiconvention.com/cdn/images/main-slider/banner_img.png"
              width="80%"
              alt=""
            />
          </figure>
        </div>

        <div className="auto-container p-2">
          <div className="content-box">
            <span className="title">16th to 18 Oct 2024, Goa</span>
            <h2>
              54th Annual <br />
              Convention of FHRAI
            </h2>
            <div className="time-counter">
              <Countdown
                date={new Date("2024-10-16T00:00:00")}
                renderer={renderer}
              />
            </div>
            <div className="btn-box">
              <a
                href="https://admin.fhraiconvention.com/static-visitor?org_id=663f4428c311bd0e45312486&exb_id=663f4578c311bd0e453126b0&form_id=663f457bc311bd0e45312c37&form=web"
                className="theme-btn btn-style-two"
              >
                <span className="btn-title">Register to Participate</span>
              </a>
            </div>
          </div>
        </div>
      </section>


{/* SLIDER SECTION START */}

{/* <section
  id="banner"
  style={{
    backgroundImage: "url(https://www.fhraiconvention.com/cdn/images/background/6.jpg)",
    background: "rgba(114, 60, 51, 0.85)",
    position: "relative",
    height: "100vh",
  }}
>
  <div className="row icon-one parallax-laye" style={{ height: "100vh", margin: 0 }}>
   
    <div className="col-lg-12" style={{ height: "100vh", padding: 0 }}>
      <Carousel style={{ height: "100vh" }}>
        <Carousel.Item>
          <a
            href="https://admin.fhraiconvention.com/static-visitor?org_id=663f4428c311bd0e45312486&amp;exb_id=663f4578c311bd0e453126b0&amp;form_id=663f457bc311bd0e45312c37&amp;form=web"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="d-block w-100"
              src="images/FHRAI-Convention.jpg"
              alt="First slide"
              style={{ height: "100vh" }}
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a
            href="https://www.wofxworldexpo.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="d-block w-100"
              src="images/furniture-expo.jpg"
              alt="Second slide"
              style={{ height: "100vh" }}
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/extarcted%20images/img339.jpg"
            alt="Third slide"
            style={{ height: "100vh" }}
          />
          <div className="carousel-caption">
            <h1 style={{ fontWeight: "bold" }}>
              WELCOME TO HOTEL AND RESTAURANT ASSOCIATION (WESTERN INDIA)
            </h1>
            <input
              type="submit"
              value="Join HRAWI"
              className="btn btn-danger"
              style={{ fontSize: "16px" }}
              onClick={() => (window.location.href = "Apply_form.aspx")}
            />
            <input
              type="submit"
              value="Read More"
              className="btn btn-secondary"
              style={{ fontSize: "16px", marginLeft: "10px" }}
              onClick={() => (window.location.href = "about_us.aspx")}
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/HRAWI_banner_2.jpg"
            alt="Fourth slide"
            style={{ height: "100vh" }}
          />
        </Carousel.Item>
      </Carousel>
    </div>

    <div
      className="col-lg-2"
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        width: "20%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "transparent", 
        padding: "10px",
      }}
    >
      <div className="vertical-slider p-2" style={{ height: "100%" }}>
        <div className="slider-content p-1">
          <div className="slider-item">
            <img
              src="images/extarcted%20images/img344.jpg"
              alt="Logo 1"
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => (window.location.href = "india")}
            />
          </div>
          <div className="slider-item">
            <img
              src="images/extarcted%20images/img345.jpg"
              alt="Logo 2"
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div className="slider-item">
            <img
              src="images/extarcted%20images/img346.jpg"
              alt="Logo 3"
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => (window.location.href = "fhrai")}
            />
          </div>
          <div className="slider-item">
            <img
              src="images/extarcted%20images/img344.jpg"
              alt="Logo 1"
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => (window.location.href = "india")}
            />
          </div>
          <div className="slider-item">
            <img
              src="images/extarcted%20images/img345.jpg"
              alt="Logo 2"
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div className="slider-item">
            <img
              src="images/extarcted%20images/img346.jpg"
              alt="Logo 3"
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => (window.location.href = "fhrai")}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}
{/* SLIDER SECTION END */}

      {/* <!--End Banner Conference Two --> */}

      {/* <!-- About Section --> */}
      <section className="about-section-two">
        <div className="anim-icons full-width">
          <span className="icon icon-circle-blue wow fadeIn"></span>
          <span className="icon icon-dots wow fadeInleft"></span>
          <span className="icon icon-circle-1 wow zoomIn"></span>
        </div>
        <div className="auto-container p-2">
          <div className="row">
            {/* <!-- Content Column --> */}
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title">About FHRAI Annual Convention</span>
                  <h2>
                    Welcome to 54th Annual
                    <br />
                    Convention of FHRAI
                  </h2>
                  <div className="text">
                    The FHRAI Annual Convention is the most sought-after event
                    in the hospitality industry, which brings together abroad
                    cross section of influential voices, policy makers, thought
                    leaders, eminent luminaries from the highest echelons of
                    government and hospitality sector
                  </div>
                  <div className="text">
                    Around 1000 delegates from India and abroad are expected to
                    attend this Convention, which includes prominent hoteliers
                    and other stakeholders from the tourism and hospitality
                    industry.
                  </div>
                </div>
                <div className="row">
                  <div className="about-block col-lg-6 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <h4>
                        <span className="icon fa fa-map-marker-alt"></span>{" "}
                        Where
                      </h4>
                      <div className="text">Taj Cidade de Goa Horizon</div>
                    </div>
                  </div>
                  <div className="about-block col-lg-6 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <h4>
                        <span className="icon fa fa-clock"></span> when
                      </h4>
                      <div className="text">
                        October 16th - 18th, 2024 09:00 AM
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- <div className="btn-box"><a href="contact.html" className="theme-btn btn-style-three"><span className="btn-title">Register Now</span></a></div> --> */}
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="image-box">
                <figure className="image wow fadeIn">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/resource/about-img-2.jpg"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End About Section --> */}

      {/* <!-- Features Section Three --> */}
      <section className="features-section-three no-pd-top">
        <div className="auto-container p-2">
          <div className="row">
            {/* <!-- Feature Block --> */}
            <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="icon-box">
                  <span className="icon flaticon-lecture"></span>
                </div>
                <h4>
                  <a href="#">Networking</a>
                </h4>
                <div className="text">
                  The Annual Convention serves as a premiere platform of tourism
                  & hospitality for networking and collaboration among industry
                  leaders, hoteliers, restaurateurs, policymakers and other
                  stakeholders.{" "}
                </div>
                <div className="link-box">
                  <a href="about.html" className="theme-btn">
                    <span className="fa fa-angle-right"></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="icon-box">
                  <span className="icon flaticon-search"></span>
                </div>
                <h4>
                  <a href="#">Innovations & Best Practices </a>
                </h4>
                <div className="text">
                  The Convention fosters the exchange of ideas, best practices,
                  and innovative solutions to drive the growth and development
                  of the hospitality sector.
                  <br />
                  <br />
                </div>
                <div className="link-box">
                  <a href="about.html" className="theme-btn">
                    <span className="fa fa-angle-right"></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <span className="icon flaticon-diamond-1"></span>
                </div>
                <h4>
                  <a href="#">Knowledge Platform</a>
                </h4>
                <div className="text">
                  The Convention features a series of insightful business
                  sessions, keynote addresses, and presentations by industry
                  experts, thought leaders, and government officials. These
                  sessions cover emerging trends, challenges, and opportunities
                  in the hospitality and tourism sectors, providing valuable
                  knowledge and strategic insights to the participants.
                </div>
                <div className="link-box">
                  <a href="about.html" className="theme-btn">
                    <span className="fa fa-angle-right"></span>
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <span className="icon flaticon-success"></span>
                </div>
                <h4>
                  <a href="#">Exhibition </a>
                </h4>
                <div className="text">
                  A Hospitality expo as part of the Convention provides a
                  platform for showcasing products, services, and technologies
                  relevant to the hospitality sector
                </div>
                <div className="link-box">
                  <a href="about.html" className="theme-btn">
                    <span className="fa fa-angle-right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Features Section --> */}

      {/* <!-- Fluid Section One --> */}
      <section className="fluid-section-one">
        <div className="outer-box clearfix">
          {/* <!-- Content Column --> */}
          <div className="content-column">
            <div className="shape-layers">
              <div className="shape-2"></div>
              <div className="shape-1"></div>
            </div>
            <div className="inner-column">
              <h3>About FHRAI</h3>
              <div className="text">
                The Federation of Hotel & Restaurant Associations of India
                (FHRAI) is the apex body and the voice of the Indian Hospitality
                Industry, representing 60,000 Hotels and 5,00,000 Restaurants
                spanning the length & breadth of our vast country. Founded in
                1955 by the legendary hotelier Rai Bahadur M.S. Oberoi, the
                Federation is privileged to have played a seminal role in the
                growth of tourism and hospitality sector of India.
              </div>
              <div className="btn-box">
                <a href="buy-ticket.html" className="theme-btn btn-style-four">
                  <span className="btn-title"> Read More</span>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Image Column --> */}
          <div
            className="image-column"
            style={{ backgroundImage: "url(images/resource/unite.jpg)" }}
          >
            <div className="image-box">
              <figure className="image">
                <img
                  src="https://www.fhraiconvention.com/cdn/images/resource/unite.jpg"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Fluid Section One --> */}

      {/* <!-- Speakers Section --> */}
      <section className="speakers-section-three">
        <div className="auto-container p-2">
          {/* <!-- <div className="sec-title text-center"> --> */}

          {/* <!-- <h2>Theme of The Convention</h2> -->
				<!-- <p>The theme of the Convention is aptly chosen as</p> -->
				<!-- <span className="title">“Tourism @2047: Incredible to Inevitable India: Driving Growth and Sustainability in a Mission Mode",</span> -->
				<!-- <p>in alignment with Government of India’s Vision of making India a developed country by 2047, wherein Indian tourism is expected to be a $3 trillion economic powerhouse with the capacity to welcome 100 million foreign visitors.</p> -->
            <!-- </div> --> */}
          <div className="sec-title text-center">
            <h2>Eminent Dignitaries</h2>
          </div>
          <div className="row">
            {speaker_Record &&
              speaker_Record.map((user, i) => (
                <div
                  key={i}
                  className="speaker-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                >
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <a href="speakers-detail.html">
                          <img
                            src={`https://d1iakh2uzy2hor.cloudfront.net/speaker/${user.photo}`}
                            alt={user.name}
                          />
                        </a>
                      </figure>
                    </div>
                    <div className="info-box">
                      <h4 className="name">
                        <a>{user.name}</a>
                      </h4>
                      <span className="designation">{user.designation}</span>

                      <span className="designation">{user.sub_title}</span>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* <!-- End Speakers Section --> */}

      <section className="features-section-two">
        <div className="auto-container p-2">
          <div className="anim-icons">
            <span className="icon twist-line-1 wow zoomIn"></span>
            <span
              className="icon twist-line-2 wow zoomIn"
              data-wow-delay="1s"
            ></span>
            <span
              className="icon twist-line-3 wow zoomIn"
              data-wow-delay="2s"
            ></span>
          </div>

          <div className="row">
            {/* <!-- Title Block --> */}
            <div className="title-block col-lg-12 col-md-12 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="sec-title  text-center">
                  <span className="title">HIGHLIGHTS OF THE </span>
                  <h2>54TH FHRAI ANNUAL CONVENTION</h2>
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/1.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">Great Speakers</a></h4> --> */}
                <div className="text">
                  The largest business and networking platform of the tourism &
                  hospitality industry
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/2.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">Experience</a></h4> --> */}
                <div className="text">
                  750+ hotel and restaurant owners and promoters in the country
                  at a single platform
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/3.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">Networking</a></h4> --> */}
                <div className="text">
                  FHRAI Hospitality
                  <br /> Awards
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/4.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">Party</a></h4> --> */}
                <div className="text">
                  B2B and B2G <br />
                  Meetings
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/5.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">New People</a></h4> --> */}
                <div className="text">
                  More than <br />
                  1000 delegates
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/6.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">New People</a></h4> --> */}
                <div className="text">
                  50+ National & <br />
                  International Speakers
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/7.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">New People</a></h4> --> */}
                <div className="text">
                  Special Zone for <br />
                  Investment Facilitation
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/8.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">New People</a></h4> --> */}
                <div className="text">
                  Hospitality & <br />
                  Supply Chain Expo
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/9.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">New People</a></h4> --> */}
                <div className="text">
                  Themed Gala <br />
                  Dinners
                </div>
              </div>
            </div>

            {/* <!-- Feature Block --> */}
            <div
              className="feature-block-two col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <img
                    src="https://www.fhraiconvention.com/cdn/images/icons/10.png"
                    alt=""
                  />
                </div>
                {/* <!-- <h4><a href="about.html">New People</a></h4> --> */}
                <div className="text">
                  Cultural
                  <br /> Performances
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="clients-section">
        <div class="anim-icons">
          <span class="icon icon-dots-3 wow zoomIn"></span>
          <span class="icon icon-circle-blue wow zoomIn"></span>
        </div>
        <div class="auto-container">
          <div class="sec-title text-center">
            {/* <!--  <span class="title">Clients</span>--> */}
            <h2>Our Sponsors</h2>
          </div>

          <div class="sponsors-outer text-center">
            <h3>Diamond Sponsors</h3>
            <div class="row">
              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-4  col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721660616274.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
              <div class="client-block col-lg-4  col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1725960275029.jpeg"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
              <div class="client-block col-lg-4  col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1725960509911.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

              {/* <!-- Client Block --> */}
              {/* <div class="client-block col-lg-3 col-md-6 col-sm-12">
                        <figure class="image-box"><a href="#"><img src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141667700.png" alt="" /></a></figure>
                    </div> */}
            </div>
          </div>

          <div class="sponsors-outer text-center">
            <h3>Exhibition Partners</h3>

            <div class="row">
              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-12 col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141707377.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div class="sponsors-outer text-center">
            <h3>Gold Sponsors</h3>

            <div class="row">
              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-12 col-md-12 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141729397.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div class="sponsors-outer text-center">
            <h3>Silver Sponsors</h3>

            <div class="row">
              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141765721.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141780647.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-3 col-md-6 col-sm-12 text-center">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141795749.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

              {/* <!-- Client Block --> */}
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141813286.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1722333764171.jpeg"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          {/* <div class="sponsors-outer">
            <h3>State Partner</h3>

            <div class="row">
              <div class="client-block col-lg-3 col-md-6 col-sm-12">
                <figure class="image-box">
                  <a href="#">
                    <img
                      src="https://d1iakh2uzy2hor.cloudfront.net/file-1721141871153.png"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section
        className="call-to-action"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/11.jpg)",
        }}
      >
        <div className="auto-container p-2">
          <div className="content-box">
            <div className="text">TOURISM @2047</div>
            <h2>Incredible to Inevitable India !</h2>
            <div className="btn-box">
              <Link to="/sponsorshipp" className="theme-btn btn-style-one">
                <span className="btn-title">Become a Sponsor</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="gallery-section style-two">
        <div className="gallery-carousel owl-carousel owl-theme">
          {/* <!-- Gallery Item --> */}
          <div className="gallery-item">
            <div className="image-box">
              <figure className="image">
                <img
                  src="https://www.fhraiconvention.com/cdn/images/gallery/01.jpg"
                  alt=""
                />
              </figure>
              <div className="overlay-box">
                <a
                  href="https://www.fhraiconvention.com/cdn/images/gallery/01.jpg"
                  className="lightbox-image"
                  data-fancybox="gallery"
                >
                  <span className="icon fa fa-expand-arrows-alt"></span>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Gallery Item --> */}
          <div className="gallery-item">
            <div className="image-box">
              <figure className="image">
                <img
                  src="https://www.fhraiconvention.com/cdn/images/gallery/02.jpg"
                  alt=""
                />
              </figure>
              <div className="overlay-box">
                <a
                  href="https://www.fhraiconvention.com/cdn/images/gallery/02.jpg"
                  className="lightbox-image"
                  data-fancybox="gallery"
                >
                  <span className="icon fa fa-expand-arrows-alt"></span>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Gallery Item --> */}
          <div className="gallery-item">
            <div className="image-box">
              <figure className="image">
                <img
                  src="https://www.fhraiconvention.com/cdn/images/gallery/03.jpg"
                  alt=""
                />
              </figure>
              <div className="overlay-box">
                <a
                  href="https://www.fhraiconvention.com/cdn/images/gallery/03.jpg"
                  className="lightbox-image"
                  data-fancybox="gallery"
                >
                  <span className="icon fa fa-expand-arrows-alt"></span>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Gallery Item --> */}
          <div className="gallery-item">
            <div className="image-box">
              <figure className="image">
                <img
                  src="https://www.fhraiconvention.com/cdn/images/gallery/04.jpg"
                  alt=""
                />
              </figure>
              <div className="overlay-box">
                <a
                  href="https://www.fhraiconvention.com/cdn/images/gallery/04.jpg"
                  className="lightbox-image"
                  data-fancybox="gallery"
                >
                  <span className="icon fa fa-expand-arrows-alt"></span>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Gallery Item --> */}
          <div className="gallery-item">
            <div className="image-box">
              <figure className="image">
                <img
                  src="https://www.fhraiconvention.com/cdn/images/gallery/05.jpg"
                  alt=""
                />
              </figure>
              <div className="overlay-box">
                <a
                  href="images/gallery/05.jpg"
                  className="lightbox-image"
                  data-fancybox="gallery"
                >
                  <span className="icon fa fa-expand-arrows-alt"></span>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Gallery Item --> */}
          {/* <!-- <div className="gallery-item"> -->
                <!-- <div className="image-box"> -->
                    <!-- <figure className="image"><img src="images/gallery/6.jpg" alt=""></figure> -->
                    <!-- <div className="overlay-box"><a href="images/gallery/6.jpg" className="lightbox-image" data-fancybox='gallery'><span className="icon fa fa-expand-arrows-alt"></span></a></div> -->
                <!-- </div> -->
            <!-- </div> --> */}
        </div>
      </section>

      <section className="event-info-section">
        <div className="auto-container p-2">
          <div className="row">
            {/* <!-- Info Column --> */}
            <div className="info-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title style-two">
                  <span className="title">Reach us</span>
                  <h2>
                    Direction for the <br />
                    Venue
                  </h2>
                </div>

                <div className="event-info-tabs tabs-box">
                  {/* <!--Tabs Box--> */}
                  <ul className="tab-buttons clearfix">
                    <li className="tab-btn active-btn" data-tab="#tab1">
                      Contact
                    </li>
                    <li className="tab-btn" data-tab="#tab2">
                      Venue
                    </li>
                    <li className="tab-btn" data-tab="#tab3">
                      Date
                    </li>
                  </ul>

                  <div className="tabs-content">
                    {/* <!--Tab--> */}
                    <div className="tab active-tab" id="tab1">
                      <h4>
                        <span className="icon fa fa-directions"></span> Further
                        Information Kindly Contact
                      </h4>
                      <h5>Payal Swami</h5>
                      <div className="text">
                        Assistant Secretary General, FHRAI
                      </div>
                      <p>
                        <span className="icon icon_mail"></span> asg@fhrai.com
                      </p>
                      <p>
                        <span className="icon icon_phone"></span> 85275 00385
                      </p>

                      <h5>Hemanshu Chauhan</h5>
                      <div className="text">
                        Assistant Secretary General, HRAWI
                      </div>
                      <p>
                        <span className="icon icon_mail"></span>asg@hrawi.com
                      </p>
                      <p>
                        <span className="icon icon_phone"></span>82911 03232
                      </p>
                    </div>
                    {/* <!--Tab--> */}
                    <div className="tab " id="tab3">
                      <h4>
                        <span className="icon far fa-calendar"></span> 16th -
                        18th October, 2024
                      </h4>
                      <div className="text">9.00 AM - 08.00 PM</div>
                      {/* <!-- <ul className="info-list"> -->
                                        <!-- <li><span className="icon icon_profile"></span> Dianne Ameter</li> -->
                                        <!-- <li><span className="icon icon_phone"></span> <a href="tel:+690-147-265-345">+123 456 7890</a></li> -->
                                        <!-- <li><span className="icon icon_mail"></span> <a href="mailto:sopport@Eventrox.com">info@expoplan.in</a></li> -->
                                    <!-- </ul> --> */}
                    </div>

                    {/* <!--Tab--> */}
                    <div className="tab" id="tab2">
                      <h4>
                        <span className="icon fa fa-map-marker-alt"></span>Taj
                        Cidade de Goa Horizon
                      </h4>
                      <div className="text">
                        Dona Paula, Vainguinim Plateau, Panaji, Goa 403004
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div className="map-column col-lg-6 col-md-12 col-sm-12">
              {/* <!--Map Outer--> */}
              <div className="map-outer">
                {/* <!--Map Canvas--> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3845.504209666376!2d73.80917287418367!3d15.457287855531236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfc7e5a12205b7%3A0xd7e5a3731f6f1e29!2sTaj%20Cidade%20de%20Goa%20Horizon%2C%20Goa!5e0!3m2!1sen!2sin!4v1715602890888!5m2!1sen!2sin"
                  height="750"
                  //   style="border:0;"
                  allowfullscreen=""
                  // loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
      className="clients-section-three"
      style={{ backgroundImage: 'url(https://www.fhraiconvention.com/cdn/images/background/9.jpg)' }}
    >
      <div className="auto-container p-2">
        <div className="upper-box">
          <div className="sec-title text-center">
            <h2 style={{ color: 'white' }}>Our Partners</h2>
          </div>
        </div>
        <div className="sponsors-outer">
          <div className="sponsors-carousel owl-carousel owl-theme">
            <div className="client-block">
              <figure className="image-box">
                <a href="#">
                  <img src="https://www.fhraiconvention.com/cdn/images/clients/2-2.png" alt="Client 1" />
                </a>
              </figure>
            </div>
            <div className="client-block">
              <figure className="image-box">
                <a href="#">
                  <img src="https://www.fhraiconvention.com/cdn/images/clients/2-3.png" alt="Client 2" />
                </a>
              </figure>
            </div>
            <div className="client-block">
              <figure className="image-box">
                <a href="#">
                  <img src="https://www.fhraiconvention.com/cdn/images/clients/2-5.png" alt="Client 3" />
                </a>
              </figure>
            </div>
            <div className="client-block">
              <figure className="image-box">
                <a href="#">
                  <img src="https://www.fhraiconvention.com/cdn/images/clients/2-6.png" alt="Client 4" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section> */}
      {/* <Footer/> */}
    </div>
  );
}

export default Home;
