import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./travel.css";
import { fetchVisitordata } from "../../Redux/Slice"; 

function ReserveHotel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isVisitor_Record = useSelector((state) => state.cms.isVisitor_Record);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false); 

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchPerformed(true); 
    dispatch(fetchVisitordata(barcodeValue));
    setBarcodeValue("");
  };

  useEffect(() => {
    if (searchPerformed) { 
      if (isVisitor_Record.length > 0) {
        if (isVisitor_Record[0]?.form_data.paymentstatus === "Paid") { 
          const record = isVisitor_Record[0];
          setSearchSuccess(true);
          toast.success("Success: Data found!");
        } else {
          toast.error("Oops! Your Payment is Pending.");
        }
      } else if (isVisitor_Record.length === 0) {
        setSearchSuccess(false);
        toast.error("Oops! Record not found.");
        setSearchPerformed(false);
      }
    }
  }, [isVisitor_Record, searchPerformed]); 

  useEffect(() => {
    const record = isVisitor_Record[0];
    if (record?.form_data && record?.form_data.membership_no) {
      const { org_id, exb_id, form_id, _id } = isVisitor_Record[0]; 
      if (isVisitor_Record[0]?.form_data.paymentstatus === "Paid") {
        const targetUrl = `https://admin.expoplan.in/traveler/static-form-visitor/${_id}`;

        // Open the target URL in a new tab/window using window.open with proper attributes
        const newWindow = window.open(
          targetUrl,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) {
          newWindow.focus(); // Focus the newly opened window
        }
      } else {
        toast.error("Oops! Your Payment is Pending.");
      }
    }
  }, [searchSuccess, isVisitor_Record]); // Navigate to visitor form on success

  const handleInputChange = (event) => {
    setBarcodeValue(event.target.value);
  };

  return (
    <div className="page-wrapper">
      <span className="header-span"></span>
      <section
        className="page-title"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/02.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>Reserved Hotel</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Reserved Hotel</li>
          </ul>
        </div>
      </section>

      <section className="contact-page-section">
        <div className="auto-container">
          <div className="form-column col-lg-8 col-md-12 col-sm-12 shadow-box">
            <h2> Enter your Registration No.</h2>
            <form className="barcode-search-form" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Enter registration number"
                name="barcode"
                className="barcode-input"
                value={barcodeValue} // Bind input value to state
                onChange={handleInputChange}
              />
              <button type="submit" className="search-button">
                Search
              </button>
            </form>
          </div>
        </div>
      </section>

      <ToastContainer />
    </div>
  );
}

export default ReserveHotel;
